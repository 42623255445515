<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
        <b-sidebar id="sendMessageToCustomer" aria-labelledby="sidebar-no-header-title" no-header backdrop width="30rem" style="direction:ltr" right title="إضافة رسالة SMS" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>مراسلة العملاء</span>
                        </div>
                        <div @click="hide" id="closeSmsg" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>اغلاق</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="12">
                        <label>اختر العميل</label>
                        <b-input-group>
                            <b-form-select
                                class="selborder"
                                v-model="customerList"
                                :options="customers"
                                multiple
                                style="height:90px;"
                            ></b-form-select>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12">
                        <label>حالة العميل</label>
                        <b-input-group>
                            <b-form-select
                                class="selborder"
                                v-model="clientStatus"
                                :options="clientStatuses"
                            ></b-form-select>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12">
                        <label>نص الرسالة</label>
                        <b-form-textarea
                            class="inborder"
                            v-model="message"
                            style="height:120px;"
                            ></b-form-textarea>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">ارسال</b-button>
                    </v-col>
                </v-row>
            </template>
        </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import {SnotifyPosition} from 'vue-snotify';
export default{
    name: 'SendMessage',
    data() {
        return {
            customerList: [],
            message: '',
            clientStatus: 0,
        }
    },
    computed: {
        ...mapGetters({
            billState: `billStore/billState`
        }),
        customers: function(){
            let t=[
                { text: 'كل العملاء', value:-1 },
                { text: 'عملاء السيارات', value: -2},
                { text: 'عملاء الفوترة', value: -3},
            ];
            for(let i=0;i<this.billState.clientList.length;i++){
                t.push({
                    text: this.billState.clientList[i].clientName,
                    value: this.billState.clientList[i].id
                });
            }
            return t;
        },
        clientStatuses: function(){
            return [
                {text: 'رسالة عادية', value: 0},
                {text: 'ايقاف نسخة العميل', value: 1},
                {text: 'اعادة تفعيل العميل بعد الايقاف', value: 2},
            ]
        }
    },
    methods:{
        ...mapActions({
            sendMsg: `billStore/sendMsg`
        }),
         addit(){
            if(this.message == ''){
                this.$snotify.error('لا يمكن ارسال رسالة فارغة', 'Error', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: 'اغلاق', 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
             this.sendMsg({customers: this.customerList,message: this.message, clientStatus: this.clientStatus});
            if(this.billState.messageStatus){
                this.$snotify.error('تم الارسال بنجاح ستظهر الرسالة لدى العملاء خلال الخمس دقائق القادمة', 'Success', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: 'اغلاق', 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                document.getElementById('closeSmsg').click();
                return true;
            }else{
                console.log("error-error","helo")
                this.$snotify.error('حدث خطأ بالارسال', 'Error', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: 'اغلاق', 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
        }
      
    }
}
</script>